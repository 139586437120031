.flipBtn {
  top: 0;
}
.removeBtn {
  top: 23px;
}
.increaseBtn {
  top: 46px;
}

.decreaseBtn {
  top: 69px;
}

.increaseBtn, .decreaseBtn, .flipBtn, .removeBtn {
  cursor: pointer;
  position: absolute;
  width: 26px;
  height: 22px;
  left: -26px;
}

.item {
  padding: 10px;
  margin: 5px 8px;
  cursor: grab;
  position: relative;
  z-index: 30;
  width: 116px;
  height: 162px;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 10px;
}

.size-0{
  width: 116px;
  height: 162px;
}

.size-1{
  width: 174px;
  height: 234px;
}

.size-2{
  width: 261px;
  height: 335px;
}

.size-3{
  width: 348px;
  height: 468px;
}

.size-4{
  width: 464px;
  height: 648px;
}

.cardWrapper {
  width: 100%;
  height: 100%;
}

// 120 x 168
// 261 x 335
