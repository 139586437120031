* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  width: 600px;
  min-height: 600px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  position: relative;
  height: 100%;
  overflow-y: scroll;

  .desc {
    color: #c0f1ba;
    margin-top: 20px;
  }
}

.field {
  min-width: 600px;
  padding: 20px 10px 0 0;
  margin: 10px 25px;
  position: relative;
  height: calc(100vh - 40px);
  min-height: 100%;
  background-color: #191d2d;
  // z-index: 20;
}

.stack {
  z-index: 30;
  border: 1px solid #2f4f4f;
  background-color: #0a1627;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 600px;
  //width: calc(100% - 50px);
  width: calc(100%);
  min-height: 242px;
  //height: 242px;
  padding: 20px 10px 0 0;
  //margin-left: 25px;
  transition-property: max-height, min-height, padding, height;
  transition-duration: 0.5s;
}

.field,
.stack {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}

.up {
  transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
}

.left {
  transform: rotate(135deg);
}

.right {
  transform: rotate(315deg);
}

.showStack,
.showDeck {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #0a1627;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2f4f4f;
  z-index: 99;

  &:hover {
    cursor: pointer;
  }
}

.showStack {
  top: -30px;
  left: -1px;
  border-bottom: none;
}

.showDeck {
  top: -1px;
  left: -30px;
  border-right: none;
}

.stackButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 9px;
  margin-bottom: 10px;
}

.stackButtons button {
  background-color: #4caf50;
  border: none;
  color: #fff;
  padding: 5px 16px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  margin-left: 5px;
  cursor: pointer;
  &:active {
    background-color: #93ad94;
  }
}

.stackButtons button:first-child {
  margin-left: 0;
}

.stackItems {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.boardTitle {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.hide {
  transition: 0.01s;
  transform: translateX(-9999px);
}

.closed {
  background-color: blue;
}

.stackContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.stackCardsWrapper {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  min-height: 162px;
}

.decksPanel {
  position: fixed;
  top: 0;
  right: 0;
  border: 1px solid #2f4f4f;
  background-color: #0a1627;
  height: 100%;
  z-index: 30;
  width: 164px;
  transition-property: width;
  transition-duration: 0.5s;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  min-height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

.activeDeck {
  border: 2px solid green;
  border-radius: 10px;
}

.deckContent {
  width: 164px;
  transition-property: width;
  transition-duration: 0.5s;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & > div {
    cursor: default;
    display: flex;
    align-self: center;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.deckContentWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  //width: 164px;
}

.stackHidden {
  padding: 0 10px;
  min-height: 0;
  height: 0;
  max-height: 0;
  transition-property: max-height, min-height, padding, height;
  transition-duration: 0.5s;

  .stackContent {
    height: 0;
    transform: translateY(400px);
    transition-timing-function: linear, ease;
    transition-duration: 0.5s;
    transition-property: opacity, transform, height;
    overflow: hidden;
  }
}

.decksHidden {
  width: 0;
  transition-property: width;
  transition-duration: 0.5s;

  .deckContent {
    width: 0;
    transform: translateX(400px);
    transition-timing-function: linear, ease;
    transition-duration: 0.5s;
    transition-property: transform, width;
    overflow: hidden;
  }
}

.size-0 {
  width: 116px;
  height: 162px;
}

.size-1 {
  width: 174px;
  height: 234px;
}

.size-2 {
  width: 261px;
  height: 335px;
}

.modal {
  background-color: #292d3e;
  color: #fff;
  border: 1px solid #464545;
  padding: 8px;
  line-height: 1.8;

  & > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 5px 10px;
    right: -16px;
    top: -16px;
    font-size: 18px;
    border-radius: 100%;
    border: 1px solid #cfcece;
    background-color: #fff;
  }
}

.popup-content {
  background-color: #292d3e;
  color: #fff;
  border: 1px solid #464545;
}

.close {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0 0;
  border: none;
  color: #000;
  cursor: pointer;
  float: right;
  line-height: 1;
  opacity: .5;
  outline: 0;
  padding: 1rem;
  font-size: 14px;
}

.roomLink {
  background-color: #9bd89e;
  padding: 2px;
  color: #292e3e;
  cursor: pointer;
  transition: background-color 0.4s;

  &:active{
    background-color: #47814a;
    transition: background-color 0.4s;
  }
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.zoomWrapper {
  margin-left: 40px;
}

.cardWrapper {
  position: relative;
  //width: 100%;
  //height: 100%;
}

.fieldCardWrapper {
  position: absolute;
}

.topbar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  width: 100%;
  z-index: 1;

  .mainNav {
    li {
      padding: 5px;
      padding-left: 10px;
      display: inline-block;
      margin-right: 10px;

      a {
        background-color: #4caf50;
        border: none;
        color: #fff;
        padding: 5px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        padding-top: 2px;
      }
    }

    h1 {
      color: #c0f1ba;
      display: inline;
      font-size: 12pt;
    }
  }
}